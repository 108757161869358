var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"hero is-primary is-medium"},[_c('div',{staticClass:"hero-head"},[_c('nav',{staticClass:"navbar"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"navbar-menu",attrs:{"id":"navbarMenuHeroA"}},[_c('div',{staticClass:"navbar-end"},[_c('a',{staticClass:"navbar-item",on:{"click":function($event){return _vm.scrollToSection('game')}}},[_vm._v(" Games ")]),_c('a',{staticClass:"navbar-item",on:{"click":function($event){return _vm.scrollToSection('about')}}},[_vm._v(" About Us ")]),_c('a',{staticClass:"navbar-item",on:{"click":function($event){return _vm.scrollToSection('contacts')}}},[_vm._v(" Contacts ")])])])])]),_vm._m(1)]),_c('div',{staticClass:"hero-body welcome"}),_c('div',{ref:"game",staticClass:"hero-body game"}),_c('div',{ref:"about",staticClass:"hero-body about"},[_vm._m(2)]),_c('div',{ref:"contacts",staticClass:"hero-body contacts"},[_c('div',{staticClass:"container"},[_c('p',{staticClass:"title contactsText"},[_vm._v("Contacts")]),_c('p',{staticClass:"subtitle contactsTextDetail"},[_vm._v(" Questions? Suggestions? we'd love to hear from you! ")]),_c('br'),_vm._m(3),_vm._m(4),_c('br'),_c('br'),_c('nav',{staticClass:"level",attrs:{"hidden":false}},[_vm._m(5),_vm._m(6)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar-brand"},[_c('a',{staticClass:"navbar-item"},[_c('img',{attrs:{"src":require("./assets/logo.png"),"alt":"Logo"}}),_vm._v(" PlayDreamGames ")]),_c('span',{staticClass:"navbar-burger",attrs:{"data-target":"navbarMenuHeroA"}},[_c('span'),_c('span'),_c('span'),_c('span')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hero slide"},[_c('div',{staticClass:"navbar-menu",attrs:{"id":"navbarMenuHeroA"}},[_c('div',{staticClass:"navbar-end"},[_c('a',{staticClass:"navbar-item active"}),_c('a',{staticClass:"navbar-item"}),_c('a',{staticClass:"navbar-item"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('p',{staticClass:"title aboutText"},[_vm._v("About Us")]),_c('p',{staticClass:"subtitle aboutTextDetail"},[_vm._v(" We are a game company founded in 2024, focusing on the development and distribution of overseas casual games. Our company members are relatively young and energetic. Our aim is to only make fun games! ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('img',{attrs:{"src":require("./assets/images/img_pic1.png")}})]),_c('div',{staticClass:"level-right"},[_c('img',{attrs:{"src":require("./assets/images/img_pic2.png")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"level"},[_c('div',{staticClass:"level-item toLeft"},[_c('img',{attrs:{"src":require("./assets/images/icon_location.png")}}),_c('p',{staticClass:"subtitle supportText"},[_vm._v("Hangzhou, Zhejiang, China ")])]),_c('div',{staticClass:"level-item has-text-centered"},[_c('img',{attrs:{"src":require("./assets/images/icon_email.png")}}),_c('p',{staticClass:"subtitle supportText"},[_vm._v("support@playdreamgames.com")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"level-item has-text-centered"},[_c('div',[_c('a',{staticClass:"policyText",attrs:{"href":"/Terms_of_Use.html"}},[_vm._v("TERMS OF USE")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"level-item has-text-centered"},[_c('div',[_c('a',{staticClass:"policyText",attrs:{"href":"/Privacy_Policy.html"}},[_vm._v("PRIVACY POLICY")])])])
}]

export { render, staticRenderFns }